import type { TldrawApp } from '@tldraw/tldraw'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { Placement, ACTIONS, EVENTS, STATUS } from 'react-joyride'
import useLocalStorage from '../hooks/useLocalStorage'
const Editor = dynamic(() => import('components/CBMultiplayerEditor'), { ssr: false })
const JoyRideNoSSR = dynamic(() => import('react-joyride'), { ssr: false })

export default function Home(): JSX.Element {
  const { query } = useRouter()
  const { boardId, focusId } = query as { boardId: string; focusId: string }
  const [playOnboarding, setPlayOnboarding] = useLocalStorage('playOnboarding', false)
  const [app, setApp] = useState<TldrawApp>()
  const [stepIndex, setStepIndex] = useState<number>(0)
  const [steps, setSteps] = useState([
    {
      target: 'html',
      title: 'Welcome to Memory Board',
      content: 'This is a space where you can get creative & have fun with your memories.',
      placement: 'center' as Placement,
    },
    {
      target: '.primaryTool',
      title: 'Memory Tools',
      content:
        'Start decorating with your memories! You can type in messages, add photos, and doodle on the board.',
    },
    {
      target: '.top-panel-right',
      title: 'Board Tools',
      content:
        "Simply undo/redo, change the board's color, and resize the board view with these buttons.",
    },
    {
      target: '.top-panel-left',
      title: 'Sharing is Caring',
      content:
        'Keeping this memory board to yourself is fun, but why stop there? Share the joy with a link or QR code.',
    },
  ])
  const [run, setRun] = useState(false)

  useEffect(() => {
    if (app) {
      if (focusId) {
        try {
          app.selectNone()
          app.select(focusId)
          app.zoomToSelection()
        } catch (err) {
          console.warn(err.message)
        }
      }
    }
  }, [app, focusId])

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      console.log('navigating guide...')
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      console.log('end')
      setPlayOnboarding(false)
      setRun(false)
    }
  }

  useEffect(() => {
    const checkUserData = () => {
      const shouldRun = localStorage.getItem('playOnboarding')
      if (shouldRun === 'true') {
        setStepIndex(0)
        setRun(true)
      }
    }
    // Hook up the event handler
    window.addEventListener('storage', checkUserData)
    return () => {
      // Remove the handler when the component unmounts
      window.removeEventListener('storage', checkUserData)
    }
  }, [])

  const handleMount = useCallback((app) => {
    setApp(app)
    if (playOnboarding) {
      setStepIndex(0)
      setRun(true)
    }
  }, [])

  console.log(run, stepIndex)

  return (
    <>
      <Editor id={`demo-${boardId}`} onMount={handleMount} />
      <JoyRideNoSSR
        steps={steps}
        stepIndex={stepIndex}
        run={run}
        continuous={true}
        showProgress={true}
        locale={{
          last: 'Done',
        }}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: '#ffffff',
            backgroundColor: '#ffffff',
            overlayColor: 'rgba(40, 40, 40, 0.4)',
            primaryColor: '#333',
            textColor: '#333',
          },
        }}
      />
    </>
  )
}
